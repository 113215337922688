.about-figure{
    max-width: 400px;
    width: 100%;
    position: relative;
    overflow: hidden;
    
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include radius(12px);
    }
}
.about-content_wrap{
    flex-wrap:wrap;
    @include breakpoint-up('xl-tab'){
        flex-wrap:nowrap
    }
}
.about-content{
    .about-des{
        p{
            &:not(:last-child){
                margin-bottom: 1rem;
            }
        }
    }
}
.contact-card{
    .is-square{
        border:1px solid var(--clr-body-100);
        color:var(--clr-primary-500);
        @include radius(4px);
        background-color: var(--clr-primary-50);
    }
}

.skill-grid{
    
    display: grid;
    gap:1.5rem;
    grid-template-columns: repeat(2, 1fr);
    @include breakpoint-up('md-tab'){
        grid-template-columns: repeat(3, 1fr);
    }
    @include breakpoint-up('md-desktop'){
        grid-template-columns: repeat(4, 1fr);
    }
}
.tab-item{
    .works-card{
        @include box-shadow(0 2px 6px rgba(0,0,0,.2));
        @include radius(12px);
        figure{
            max-height: 200px;
            position: relative;
            overflow: hidden;
            margin-bottom: 1rem;
            img{
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
    }
    .grid-cols_3{
        display: grid;
        gap:1.5rem;
        grid-template-columns: 1fr;
        @include breakpoint-up('md-tab'){
            grid-template-columns: repeat(2, 1fr);
        }
        @include breakpoint-up('md-desktop'){
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
.dark{
    .tab-item{
        .works-card{
            @include box-shadow(0 2px 6px rgba(255,255,255,.2));
        }
    }
}