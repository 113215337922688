.mobile-menu-icon {
  cursor: pointer;
  i {
    @include radius(50%);
    padding: 5px;
    color: var(--clr-white-900);
  }
  @include breakpoint-up('md-desktop') {
    // display: none;
  }
  &.is-position {
    position: absolute;
    right: -34px;
    top: 2%;

    i {
      background-color: var(--clr-primary-50);
      @include radius(4px);
      color: var(--clr-primary-500);
    }
  }
}

.dbd-sidebar {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: none;
  z-index: 111111;
  width: 250px;
  padding: 0.6125rem;
  padding-top: 1rem;

  justify-content: stretch;
  transition: width 200ms ease-in-out;

  .aside-header {
    padding-bottom: 1rem;
    max-height: 50px;
    min-height: 50px;
    border-bottom: 1px solid var(--clr-body-300);
    svg {
      max-height: 30px;
    }
    img {
      max-height: 30px;
    }
    figure {
      transition: height 200ms ease-in-out;
    }
  }
  .is-overflow {
    position: relative;
    height: 100%;
    margin-top: 1rem;
    @include scrollbar(
      var(--clr-body-400),
      var(--clr-body-600),
      var(--clr-primary-700)
    );
  }
  ul {
    .menu-list_icon {
      margin-bottom: 0;
      width: 24px;
      max-height: 24px;
      text-align: center;
      flex-shrink: 0;
      svg {
        max-height: 24px;
      }
    }
    .menu-list_hidden {
      text-transform: capitalize;
      @include trim(1);
      margin-left: 1.5rem;
      transition: opacity 200ms ease-in-out;
    }
    li {
      position: relative;
      width: 100%;
      font-weight: 600;
      a {
        @include flexbox();
        @include align-items(center);
        padding: 0.5rem 0.5rem;
        width: 100%;
        transition: 200ms ease-in-out;

        &:hover {
          color: var(--clr-primary-500);
        }
      }
      &.is-active {
        a {
          background-color: var(--clr-secondary-50);
          color: var(--clr-primary-500);
        }
        ul {
          li {
            a {
              background-color: transparent;
              &::before {
                display: none;
              }
            }
          }
        }
      }
      ul {
        display: none;
        li {
          a {
            padding: 0.6125rem 1rem 0.6125rem 1.5rem;
            text-transform: capitalize;
            min-height: initial;
            width: 100%;
            font-size: var(--fs-sm);
            &:hover {
              color: var(--clr-secondary-300);
            }
          }
        }
        &.open {
          display: block;
        }
      }
    }
  }
  .aside-footer {
    max-height: 62px;
    min-height: 62px;
    border-top: 1px solid var(--clr-body-300);
    .af-content {
      text-align: center;
    }
    p {
      font-weight: 600;
      font-size: var(--fs-md);
    }
    .pf-img {
      width: 72px;
      text-align: center;
    }
    .more_vert {
      opacity: 0.7;
    }
    .is-circle.is-sm {
      width: 30px;
      height: 30px;
      max-width: 30px;
      margin: 0 auto;
    }
    .pm-dropdown {
      margin-top: 1rem;
      padding-right: 1rem;
    }
    &:hover {
      .more_vert {
        opacity: 1;
      }
    }
  }
  &_shadow {
    @include box-shadow(0 0 8px rgba(#000, 0.2));
  }

  &_primary {
    background-color: var(--clr-primary-500);
    .mobile-menu-icon {
      i {
        color: var(--clr-white-900);
      }
    }
    .menu {
      ul {
        li {
          a {
            color: var(--clr-white-900);
          }

          &.is-active,
          &:hover {
            a {
              background-color: var(--clr-primary-900);
            }
          }
          ul {
            background-color: var(--clr-primary-500);
          }
        }
      }
    }
  }
  &_transparent {
    background-color: transparent;
  }
  &_white {
    background-color: var(--clr-white-900);
  }
  &_before {
    .menu {
      ul {
        li {
          &::before {
            content: '';
            width: 3px;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            background-color: transparent;
          }
        }
      }
    }
  }
  &_active {
    width: 70px;
    .aside-header {
      figure {
        opacity: 0;
        height: 0;
        width: 0;
        visibility: hidden;
        img {
          max-height: 40px;
        }
      }
    }
    .is-overflow {
      overflow-y: visible !important;
    }
    .menu {
      ul {
        li {
          &.menu-toggle {
            &::before,
            &::after {
              display: none;
            }
          }
          ul {
            li {
              a {
                padding: 0.5rem;
              }
            }
          }
          &:hover {
            ul {
              display: block;
              position: absolute;
              left: 100%;
              top: 0;
              z-index: 99;
              min-width: 150px;
            }
          }
        }
      }
    }
    .aside-footer {
      .af-content {
        display: none;
      }
      .pm-dropdown {
        display: block;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    width: 70px;
    .aside-header {
      figure {
        opacity: 0;
        height: 0;
        width: 0;
        visibility: hidden;
        img {
          max-height: 40px;
        }
      }
    }
    .is-overflow {
      overflow-y: visible !important;
    }
    .menu {
      ul {
        li {
          &.menu-toggle {
            &::before,
            &::after {
              display: none;
            }
          }
          ul {
            li {
              a {
                padding: 0.5rem;
              }
            }
          }
          &:hover {
            ul {
              display: block;
              position: absolute;
              left: 100%;
              top: 0;
              z-index: 99;
              min-width: 150px;
            }
          }
        }
      }
    }
    .aside-footer {
      .af-content {
        display: none;
      }
      .pm-dropdown {
        display: block;
      }
    }
  }
}

// @include prefix(transition, (all 0.1s ease), ('webkit', 'moz', 'ms', 'o'));
