@import 'switcher';
@import 'button';
@import 'field';
@import 'checkbox';
@import 'search';
@import 'social-icons';
@import 'pm-dropdown';
@import 'pm-select';
@import 'pm-upload';
@import 'progress';
@import './date-picker';
@import 'range-slider';

.pm-group {
  select {
    &.pm-control {
      -webkit-outline: none;
      outline: none;
    }
  }
}
.mathmetic-symbols{
  li{
    padding:.6125rem;
    border:1px solid var(--clr-body-300);
    @include radius(6px);
    color:var(--clr-secondary-500);
    font-weight: bold;
    cursor: pointer;
    &.is-active{
      background-color: var(--clr-white-900);
      border-color:var(--clr-secondary-500);
    }
  }
}
