.pm-card {
  position: relative;
  @include flexbox();
  @include flex-direction(column);
  min-width: 0;
  word-wrap: break-word;

  &_shadow {
    @include box-shadow(0 0 16px rgba(0, 0, 0, 0.12));
  }
  &_radius {
    border-radius: 6px;
  }
  &_white {
    background-color: var(--clr-white-900);
  }
  &_bg {
    background-color: var(--clr-body-100);
  }
  &_border {
    border: 1px solid var(--clr-body-300);
  }
  &_border-dash {
    border: 2px dashed var(--clr-body-300);
  }
  &_body {
    flex: 1 1 auto;
    min-height: 1px;
  }
  figure{
    &.is-fit{
      position: relative;
      overflow: hidden;
      display: block;
      min-height: 200px;
      max-height: 200px;
      img{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }
  }
}
