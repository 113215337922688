.upload {
  position: relative;
  padding: 3rem;
  border: 2px dashed var(--clr-body-300);
  @include radius(10px);
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include flex-direction(column);
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    z-index: 99;
  }
  label {
    display: initial;
    text-align: center;
    text-transform: initial;
    .upload-icon {
      display: block;
      max-width: 40px;
      margin: 0 auto;
      color:var(--clr-primary-500);
    }
    .upload-text {
      font-size: var(--fs-body);
      h4 {
        margin-bottom: 0.6125rem;
        color: var(--heading-color);
      }
      p {
        margin: 0;
        color: var(--heading-color);
        a {
          color: var(--secondary-color);
          font-weight: 600;
        }
      }
    }
  }
}

.pmupload-file {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
  background-color: var(--clr-body-100);
  padding: 5px 10px;
  border-radius: 6px;
  .pmupload_content {
    @include flexbox();
    @include justify-content(flex-start);
    @include align-items(center);
    figure {
      width: 40px;
      height: 40px;
      padding: 5px;
      background-color: var(--clr-white-900);
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      @include flex-direction(column);
      @include flex(0 0 40px);
      max-width: 40px;
      @include radius(50%);
      i {
        color: var(--clr-body-600);
      }
    }
  }

  
  .file-type {
    margin-left: 0.6125rem;
    h6 {
      font-size: var(--fs-body);
      font-family: var(--ff-bd);
    }
    span {
      font-size: var(--fs-body);
    }
  }
  &.is-light {
    background-color: var(--clr-primary-100);
    @include radius(5px);
    max-width: 400px;
  }
}

.attached {
  position: relative;
  width: 100%;
  h6 {
    text-transform: capitalize;
    margin-left: 0.5rem;
  }
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    z-index: 99;
  }
}

.is-error {
  background-color: rgba(var(--clr-red-900), 0.1);
  padding: 0.6125rem;
  @include radius(5px);
  i {
    color: var(--clr-red-900);
    font-size: 1rem;
  }
  .error-content {
    padding-left: 1rem;
    h6 {
      font-family: var(--ff-bd);
      font-weight: 500;
    }
    span {
      font-size: var(--fs-body);
    }
  }
  &.is-success {
    background-color: var(--green-light);
    border: 2px solid var(--green-color);
    i {
      color: var(--green-color);
    }
  }
}

.custom-upload {
  @include flexbox();
  @include justify-content(flex-start);
  @include align-items(center);
  position: relative;
  .img-holder {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    @include flex-direction(column);
    width: 60px;
    height: 60px;
    border: 1px solid var(--clr-body-300);
    @include radius(50%);
    padding: 5px;
    background-color: var(--primary-light);
    img {
      max-height: 45px;
      height: auto;
      width: auto;
      display: block;
    }
  }
  .custom-file {
    min-height: 83px;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    @include flex-direction(column);
    padding: 1rem;
    text-align: center;
    input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 1;
    }
    label {
      font-weight: 600;
    }
  }
}

.pmupload-btn {
  label {
    position: relative;
    margin: 0;
    display: flex;
    font-weight: 600;
    i {
      font-size: var(--fs-h6);
      line-height: 1;
      display: inline-flex;
      margin: 0 0.5rem 0 0;
    }

    input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 1;
    }
  }
  &.is-border {
    label {
      background-color: transparent;
      color: var(--primary-300);
      border-color: var(--primary-300);
    }
  }
}

.upload-image {
  position: relative;
  min-height: 300px;
  max-height: 300px;
  @include radius(10px);
  border: 2px dashed var(--clr-body-300);
  overflow: hidden;
  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    @include radius(10px);
  }
  .remove-caption {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background-color: rgba(var(--heading-color), 0.2);
    padding: 5px;
    color: var(--clr-white-900);
    i {
      font-size: 1rem;
    }
  }
}

.uploaded-photos {
  a {
    width: 50px;
    height: 50px;
    @include radius(5px);
    overflow: hidden;
    position: relative;
    @include flexbox();
    .cross-icon {
      position: absolute;
      top: 50%;
      margin: 0 auto;
      @include prefix(
        transform,
        translateY(-50%),
        ('webkit', 'moz', 'ms', 'o')
      );
      z-index: 3;
      left: 15px;
      width: 28px;
      height: 28px;
      background-color: rgba(0, 0, 0, 0.5);
      color: var(--clr-white-900);
      @include radius(100%);
    }
    .overlay {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.5);
      text-align: center;
      opacity: 0;
      visibility: hidden;
    }
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      @include radius(5px);
      object-fit: cover;
      object-position: center center;
      top: 0;
      bottom: 0;
    }
    &:hover {
      .overlay {
        opacity: 1;
        visibility: visible;
      }
    }
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
