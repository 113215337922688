main{
    background-color: var(--clr-white-900);
    @include radius(12px);
    padding:1.5rem;
    margin:2rem 0;
    min-height: calc(100vh - 130px);
    @include breakpoint-up('xl-tab'){
        padding:4rem;
    }


    &.home{
        .home-profile{
            text-align: center;
            figure{
                max-width: 250px;
                height: 250px;
                position: relative;
                overflow: hidden;
                border-radius: 100%;
                margin:0 auto;
                padding:10px;
                background: rgba(255, 255, 255, 0.2);
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                border: 1px solid rgba(255, 255, 255, 0.3);
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    .social-icons{
        .is-square{
            color:var(--clr-primary-500);
            background-color: var(--clr-primary-50);
            backdrop-filter: blur( 2px );
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            -webkit-backdrop-filter: blur( 2px );
            @include radius(8px);
            transition:  all .5s ease-in-out;
            &:hover{
                background-color: var(--clr-primary-100);
            }
            
        }
    }
}
