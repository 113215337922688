/// Mixin helper to output vendor prefixes
$known-prefixes: webkit, moz, ms, o;

@mixin prefix($property, $value, $prefixes) {
  @each $prefix in $prefixes {
    @if not index($known-prefixes, $prefix) {
      @warn "Unknown prefix #{$prefix}.";
    }

    -#{$prefix}-#{$property}: $value;
  }
  #{$property}: $value;
}

// @mixin prefix($property, $value, $prefixes: ()) {
//     @each $prefix in $prefixes {
//         -#{$prefix}-#{$property}: $value;
//     }
//     #{$property}: $value;
// }





// calling method 
// @include size(100%, 10em);
// scss-docs-start mixin-color-scheme
@mixin color-scheme($name) {
    @media (prefers-color-scheme: #{$name}) {
        @content;
    }
}



@import "dark-mode";
@import "flex";
@import "radius";
@import "break-word";
@import "boxshadow";
@import "placeholder";
@import "cover";
@import "ellipsis";
@import "clear";
// @import "backface-visibility";
@import "scrollbar";
@import "opacity";
@import "user-select";
@import "animation";