// :root[data-theme="light"] {
// }

// :root[data-theme="light"] {
// }

$colors: (
  primary: (
    50: #DFF2F1,
    100: #B0DFDA,
    200: #7BCBC3,
    300: #42B6AA,
    400: #00A699,
    500: #00A699,
    600: #009686,
    700: #3B8779,
    800: #347769,
    900: #2C675A,
  ),
  secondary: (
    50: #DFF2F1,
    100: #B0DFDA,
    200: #7BCBC3,
    300: #42B6AA,
    400: #00A699,
    500: #00A699,
    600: #009686,
    700: #3B8779,
    800: #347769,
    900: #2C675A,
  ),
  body: (
    50: #FBFBFB,
    100: #F2F2F2,
    200: #EBEBEB,
    300: #d7d7d7,
    400: #f5f5f5,
    500: #C4C4C4,
    600: #484848,
    700: #616161,
    800: #424242,
    900: #111111,
  ),
  white: (
    900: #ffffff,
  ),
  orange: (
    50: #fff8e1,
    100: #ffecb2,
    200: #ffdf81,
    300: #ffd44e,
    400: #ffc926,
    500: #ffc001,
    600: #ffb100,
    700: #ff9e00,
    800: #ff8d00,
    900: #ff6d00,
  ),
  blue: (
    50: #e2f3ff,
    100: #bae0ff,
    200: #8bceff,
    300: #53baff,
    400: #14aaff,
    500: #009aff,
    600: #008bff,
    700: #0077ff,
    800: #1564ec,
    900: #2242cd,
  ),
  red: (
    50: #ffe9ec,
    100: #ffc8cc,
    200: #f9908e,
    300: #f26261,
    400: #fb3637,
    500: #ff0f0f,
    600: #f10013,
    700: #df000e,
    800: #d20004,
    900: #c50000,
  ),
  purple: (
    50: #f3e5f5,
    100: #e1bee7,
    200: #ce93d8,
    300: #ba68c8,
    400: #aa47bc,
    500: #9b27b0,
    600: #8d24aa,
    700: #7a1fa2,
    800: #691b9a,
    900: #49148c,
  ),
  green: (
    50: #e7f6e9,
    100: #c7e8c8,
    200: #a2d9a5,
    300: #7ccb81,
    400: #5ec065,
    500: #40b44a,
    600: #37a541,
    700: #2b9335,
    800: #1f822b,
    900: #006317,
  ),
  yellow: (
    50: #fff8e1,
    100: #ffecb3,
    200: #ffe082,
    300: #ffd54f,
    400: #ffca28,
    500: #ffc007,
    600: #ffb300,
    700: #ffa000,
    800: #ff8e00,
    900: #ff6e01,
  ),
);


:root {
  @each $color, $shades in $colors {
    @each $shade, $value in $shades {
      --clr-#{$color}-#{$shade}: #{$value};
    } 
  }

}

body.dark{
  --clr-primary-50: #313131;;
  --clr-primary-100: #B0DFDA;
  --clr-primary-200: #7BCBC3;
  --clr-primary-300: #42B6AA;
  --clr-primary-400: #00A699;
  --clr-primary-500: #00A699;
  --clr-primary-600: #009686;
  --clr-primary-700: #3B8779;
  --clr-primary-800: #347769;
  --clr-primary-900: #2C675A;
    --clr-secondary-50: #DFF2F1;
    --clr-secondary-100: #B0DFDA;
    --clr-secondary-200: #7BCBC3;
    --clr-secondary-300: #42B6AA;
    --clr-secondary-400: #00A699;
    --clr-secondary-500: #00A699;
    --clr-secondary-600: #009686;
    --clr-secondary-700: #3B8779;
    --clr-secondary-800: #347769;
    --clr-secondary-900: #2C675A;
    --clr-body-50: #000000;
    --clr-body-100: #000000;
    --clr-body-200: #434343;
    --clr-body-300: #555555;
    --clr-body-400: #7B7B7B;
    --clr-body-500: #9D9D9D;
    --clr-body-600: #C4C4C4;
    --clr-body-700: #D9D9D9;
    --clr-body-800: #E9E9E9;
    --clr-body-900: #F5F5F5;
    --clr-white-900: #151515;
    --clr-orange-50: #fff8e1;
    --clr-orange-100: #ffecb2;
    --clr-orange-200: #ffdf81;
    --clr-orange-300: #ffd44e;
    --clr-orange-400: #ffc926;
    --clr-orange-500: #ffc001;
    --clr-orange-600: #ffb100;
    --clr-orange-700: #ff9e00;
    --clr-orange-800: #ff8d00;
    --clr-orange-900: #ff6d00;
    --clr-blue-50: #e2f3ff;
    --clr-blue-100: #bae0ff;
    --clr-blue-200: #8bceff;
    --clr-blue-300: #53baff;
    --clr-blue-400: #14aaff;
    --clr-blue-500: #009aff;
    --clr-blue-600: #008bff;
    --clr-blue-700: #0077ff;
    --clr-blue-800: #1564ec;
    --clr-blue-900: #2242cd;
    --clr-red-50: #ffe9ec;
    --clr-red-100: #ffc8cc;
    --clr-red-200: #f9908e;
    --clr-red-300: #f26261;
    --clr-red-400: #fb3637;
    --clr-red-500: #ff0f0f;
    --clr-red-600: #f10013;
    --clr-red-700: #df000e;
    --clr-red-800: #d20004;
    --clr-red-900: #c50000;
    --clr-purple-50: #3a3a3a;
    --clr-purple-100: #e1bee7;
    --clr-purple-200: #ce93d8;
    --clr-purple-300: #ba68c8;
    --clr-purple-400: #aa47bc;
    --clr-purple-500: #9b27b0;
    --clr-purple-600: #8d24aa;
    --clr-purple-700: #7a1fa2;
    --clr-purple-800: #691b9a;
    --clr-purple-900: #49148c;
    --clr-green-50: #e7f6e9;
    --clr-green-100: #c7e8c8;
    --clr-green-200: #a2d9a5;
    --clr-green-300: #7ccb81;
    --clr-green-400: #5ec065;
    --clr-green-500: #40b44a;
    --clr-green-600: #37a541;
    --clr-green-700: #2b9335;
    --clr-green-800: #1f822b;
    --clr-green-900: #006317;
    --clr-yellow-50: #fff8e1;
    --clr-yellow-100: #ffecb3;
    --clr-yellow-200: #ffe082;
    --clr-yellow-300: #ffd54f;
    --clr-yellow-400: #ffca28;
    --clr-yellow-500: #ffc007;
    --clr-yellow-600: #ffb300;
    --clr-yellow-700: #ffa000;
    --clr-yellow-800: #ff8e00;
    --clr-yellow-900: #ff6e01;
}
