input[type='date'].pm-control,
input[type='datetime-local'].pm-control,
input[type='month'].pm-control,
input[type='time'].pm-control {
  @include user-select(none);
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role='button'] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button;
}

[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

input,
textarea {
  &.pm-control {
    display: block;
    width: 100%;
    height: calc(1.8em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border-radius: 5px;
    color:var(--clr-body);
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid var(--clr-body-300);
    
    &_white {
      background-color: var(--clr-white-900);
    }
  

    @include input-placeholder {
      color: var(--clr-body-500);
      text-transform: capitalize;
    }

    &_valid {
      border-color: var(--clr-primary-500);
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

      &:focus {
        border-color: var(--clr-primary-500);
      }
    }

    &_invalid,
    &:invalid {
      border-color: var(--clr-red);
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

      &:focus {
        border-color: var(--clr-red);
        box-shadow: 0 0 0 0.2rem rgba(var(--red-color), 0.25);
      }
    }

    &:focus {
      box-shadow: none;
      outline: none;
      -webkit-outline: none;
      border-color: var(--clr-body-600);
      color: var(--clr-body-600);
      background-color: var(--clr-body-100);
    }
  }
}

.pm-group {
  margin-bottom: 1.2rem;

  label {
    text-transform: capitalize;
    margin-bottom: 0.5rem;
    display: block;

    i {
      margin-left: 0.5rem;
    }

    sup {
      color: var(--clr-red);
    }
  }

  span {
    &.is-error {
      color: var(--clr-primary-600);
      margin-top: 0.5rem;
    }
  }
  &.sm-input{
    max-width: 100px;
  }
}

textarea {
  &.pm-control {
    min-height: 100px;
  }
}

.custom-input-group {
  @include flexbox();
  @include justify-content(space-between);
  border: 1px solid var(--clr-body-300);
  @include align-items(center);
  background-color: var(--clr-white-900);
  position: relative;
  height: calc(1.8em + 0.75rem + 2px);
  overflow: hidden;
  border-radius: 6px;


  input {
    flex-grow: 1;
    border: none;
    display: initial;
    background-color: var(--clr-white-900);
    width: 100%;
  }

  span {
    &.span-group {
      text-align: center;
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      @include flex-direction(column);
      cursor: pointer;
      color:var(--clr-body-600);

      i {
        font-size: 1.1rem;
      }
    }

    &.start-date {
      padding-left: 0.5rem;
    }

    &.end-date {
      padding-left: 0.5rem;
    }
  }

  &.is-date {
    padding-right: 1.5rem;
    @include justify-content(flex-start);

    &::after {
      content: 'arrow_drop_down';
      font-family: 'Material Icons';
      position: absolute;
      right: 0;
      top: 50%;
      @include prefix(
        transform,
        translateY(-50%),
        ('webkit', 'ms', 'moz', 'o')
      );
      font-size: 1.5rem;
      z-index: 99;
      pointer-events: none;
    }

    span {
      &.span-group {
        i {
          color: var(--clr-body-400);
          font-size: 1.1rem;
        }
      }
    }
  }

  &.is-bg {
    background-color: transparent;
    border: 0;

    input {
      min-height: calc(2.5rem + 0.75rem + 2px);
    }

    span {
      &.span-group {
        padding: 0 1rem;
      }
    }
  }
  &:hover {
  }
  &:focus-within {
    border-color: var(--clr-body-600);
    background-color: var(--clr-body-100);
  }
}

.date-range {
  @include flexbox();
  @include justify-content(space-between);
  @include radius(5px);
  @include align-items(stretch);
  background-color: var(--clr-white-900);

  .custom-input-group {
    border: none;
    border-radius: none;
    border-right: 1px solid var(--clr-body-400);

    input {
      min-width: auto;
      padding-left: 0.5rem;
    }
  }
}
