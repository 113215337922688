table td,
table th {
  padding: 0.5rem 0.75rem;
}

.pm-table {
  display: block;
  width: 100%;
  @include scrollbar(
    var(--clr-body-300),
    var(--clr-body-400),
    var(--clr-primary-700)
  );
  overflow: hidden;
    &:hover{
      overflow: auto;
      overflow-x: auto;
    }
  &_border {
    table {
      tbody {
        tr {
          border-bottom: 1px solid var(--clr-body-300);
        }
      }
    }
  }

  table {
    width: 100%;
    margin-bottom: 1rem;
    border-collapse: collapse;
    thead {
      tr {
        th {
          vertical-align: middle;
          font-size: var(--fs-md);
          border: none;
          text-align: left;
          position: relative;
          font-weight: bold;
          text-transform: uppercase;
          color: var(--clr-body-500);
          .pm-table_arrow {
            .updown-arrow {
              // @include flexbox();
              // @include justify-content(center);
              // @include align-items(center);
              // @include flex-direction(column);
              cursor: pointer;
            }
            i {
              line-height: 1;
              cursor: pointer;
              font-size: 1.1rem;
            }

            // &.is-active {
            //   color: var(--clr-primary-500);
            //   opacity: 1;
            //   visibility: visible;
            //   svg {
            //     fill: var(--clr-primary-500);
            //   }
            //   .updown-arrow {
            //     opacity: 1;
            //     visibility: visible;
            //   }
            // }
          }
          &.is-active{
            color: var(--clr-body-900);
            .pm-table_arrow{
              i {
                line-height: 1;
                cursor: pointer;
                font-size: 1.4rem;
                margin-top:-.25rem;
              }
            }
          }
          &:hover{
            color: var(--clr-body-900);
            cursor: pointer;
          }
          &.serial-no {
            width: 50px;
          }
        }
      }
    }
    .custom-checkbox {
      margin: 0;
    }

    tbody{
      tr{
        position: relative;
        td{
          
          &.is-sticky-td{
            position: fixed;
            right:10px;
            padding-top:0;
            z-index: 1;
            .pm-dropdown{
              ul.pm-dropdown_menu{
                z-index: 9999999;
                overflow: hidden;
              }
            }
          }
        }
        &:hover {
          background-color: var(--clr-body-100);
        }
        &.theader{
          p{
            position: relative;
            &::before{
              font-size: 1.4rem;
              font-family: 'Material Icons';
              content: "expand_more";
              color: var(--clr-body-color);
              z-index: 99;
              line-height: 1;
              @include flexbox();
              @include justify-content(center);
              @include align-items(center);
              @include flex-direction(column);
              @include radius(100%);
              position: absolute;
              left: 0;
              top:0;
            }
          }
          &_active{
            p{
              &::before{
                content: "expand_less";
              }
            }
          }
        }
      }
    }
    
    .photos {
      .photo {
        max-width: 24px;
        width: 100%;
        height: 40px;
        max-height: 50px;
        position: relative;
        border: 1px solid var(--primary-100);
        @include radius(4px);
        img {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }
    }
    .is-status {
      display: block;
      i {
      }
      &.is-before {
        display: inline-block;
      }
      &.is-flex {
        @include flexbox();
      }
    }

    .is-circle {
      &.is-circle_xs {
        max-width: 32px;
        height: 32px;
        &:hover{
          background-color: var(--clr-white-900);
        }
      }
    }
  }
  &_sticky {
    overflow-x: unset;
    table {
      thead {
        tr th {
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          background-color: var(--clr-white-900);
          z-index: 999;
          padding: 0.5rem 0.6125rem;
        }
      }
    }
  }
  &.is-white {
    table {
      tr {
        border-bottom: 1px solid var(--clr-body-300);
        td,
        th {
          background-color: var(--clr-white-900);
        }
      }
    }
    &.is-sticky {
      table {
        th {
          background-color: var(--bg-color);
        }
      }
    }
  }
  &.non-overflow {
    overflow-y: initial;
    table {
      th,
      td {
        border: none;
        padding: 0.6125rem 0.775rem;
      }
    }
  }
}

.table-pagination {
  .paging-left {
    .pm-select {
      max-width: 80px;
      p {
        &.selected-item {
          min-width: 80px;
        }
      }
      .select-list {
        top: auto;
        bottom: 100%;
        min-width: 80px;
      }
    }
    span {
      &.row-text {
        margin-right: 0.5rem;
      }
    }
  }
  .paging-right {
    flex-grow: 1;
    input {
      max-width: 50px;
      height: calc(1em + 0.75rem + 2px);
    }
  }
  p {
    &.row-count {
      span {
        &:nth-child(2) {
          margin: 0 0.5rem;
        }
      }
    }
    &.next-prev {
      span {
        cursor: pointer;
      }
    }
    &.jumps {
      span {
        margin-right: 0.5rem;
      }
    }
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
