.is-loading {
    background: #F6F6F6;
    position: relative;
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        bottom: 0;
        top: 0;
        z-index: 1111;
        animation-duration: 3.5s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: contentLoader;
        animation-timing-function: linear;
        background: linear-gradient(to right, #F6F6F6 25%, #F0F0F0 50%, #F6F6F6 100%);
    }
}



@keyframes contentLoader {
    0% {
        background-position: -300px 0
    }

    100% {
        background-position: 300px 0
    }
}