nav{
    transition: width .5s;
    ul{
        width: 100%;
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        gap:0 1rem;
        flex-direction: row;
        @include breakpoint-down("lg-tab"){
          flex-direction: column; 
          gap:.6125rem; 
          li{
            width: 100%;
            }
        }
        
        li{
            a{
                font-weight: 600;
                text-transform: capitalize;
                color:var(--clr-body);
                padding:.5rem 1rem;
                border-radius: 4px;
                background-color:var(--clr-white-900);
                transition: color .5s;
                @include breakpoint-up('md-desktop'){
                    padding:.5rem 1.5rem;
                }
               
            }
            &.is-active{
                a{
                    background-color: var(--clr-primary-500);
                    color:var(--clr-white-900);
                }
            }
            &:hover{
                color:var(--clr-secondary-500);
                opacity: 1;
            }
        }
    }
}


    
.toggle-menu{
    opacity: 0;
    visibility: hidden;
    width: 0;
    height: 0;
    background-color: var(--clr-white-900);
    border-radius: 8px;
    
    
    svg{
        height: 1.5rem;
        width: 1.5rem;
        
    }
    @include breakpoint-down("lg-tab"){
        padding:.45rem .5rem;
        visibility: visible;
        opacity: 1;
        width: auto;
        height: auto;
        @include box-shadow(0 2px 6px rgba(0,0,0,.4));
    }
    
}
.menu{
    .switch-button{
        background-color: var(--clr-white-900);
        svg{
            width: 1.25rem;
            height: 1.25rem;
        }
    }
    nav{
       
  
        @include breakpoint-down("lg-tab"){
            display: none;
            position: fixed;
            opacity: 0;
            visibility: hidden;
            right:-100%;
            width: 300px;
            background-color: var(--clr-white-900);
            top:0;
            height: 100vh;
            padding:1rem;
            box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
            border:1px solid var(--clr-body-300);
           z-index: 111;
            
           
            
        }
    }
    &_active{
        nav{
            @include breakpoint-down("lg-tab"){
                display: block;
                opacity: 1;
                visibility: visible;
                right:0; 
            }  
        }
    }
}


